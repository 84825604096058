<template>
  <div>
    <Modal>
      <div class="title">Configuración</div>
      <div class="body">
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="tmp_conf.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="tipo" class="col-form-label col-sm-3">Tipo</label>
          <div class="col-sm-9">
            <select v-model="tmp_conf.tipo" name="tipo" id="tipo" class="form-control">
              <option value="string">String</option>
              <option value="numeric">Numeric</option>
              <option value="float">Float</option>
              <option value="array">Array</option>
              <option value="object">Object</option>
              <option value="boolean">Boolean</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="seguro" class="col-form-label col-sm-3">Seguro</label>
          <div class="col-sm-9">
            <BpbSwitch v-model="tmp_conf.valor_seguro" id="seguro" name="seguro" texton="Sí" textoff="No"/>
          </div>
        </div>
        <div class="row form-group">
          <label for="valor" class="col-form-label col-sm-3">Valor</label>
          <div class="col-sm-9">
            <textarea v-model="tmp_conf.valor" class="form-control" name="valor" id="valor" cols="30" rows="5"></textarea>
          </div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-3">Descripción</label>
          <div class="col-sm-9">
            <textarea v-model="tmp_conf.descripcion" class="form-control" name="descripcion" id="descripcion" cols="30" rows="5"></textarea>
          </div>
        </div>
      </div>
      <div class="footer text-right">
        <button class="btn btn-primary mr-2" @click="guardar_conf">Agregar</button>
        <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import BpbSwitch from '@/components/Form/Switch';

import api from '@/apps/fyc/api/configuracion';

export default {
  components: {
    Modal, BpbSwitch
  },
  props: {
    conf: {
      type: Object,
    }
  },
  data() {
    return {
      tmp_conf: {
        nombre: null,
        tipo: 'string',
        valor_seguro: false,
        valor: null,
        descripcion: null
      }
    }
  },
  mounted() {
    if (this.conf) {
      this.tmp_conf = this.$helper.clone(this.conf);
    }
  },
  methods: {
    async guardar_conf() {
      try {
        if (!this.tmp_conf.id)
          await api.agregar_configuracion(this.tmp_conf);
        else
          await api.editar_configuracion(this.tmp_conf.id, this.tmp_conf);

          this.$emit('update');
      }catch (e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>