import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_COFINANCIAMIENTO+'/configuraciones');

export default {
  obtener_configuraciones() {
    return http.get('/');
  },
  agregar_configuracion(payload) {
    return http.post('/', payload);
  },
  editar_configuracion(id, payload) {
    return http.put(`/${id}`, payload);
  }
}